import { type App } from 'vue'
import AppButton from './app-button.vue'
import AppEmpty from './app-empty.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppIcon from './app-icon.vue'
import AppImage from './app-image.vue'
import AppInput from './app-input.vue'
import AppInputFile from './app-input-file.vue'
import AppInputSearch from './app-input-search.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppModal from './app-modal.vue'
import AppPagination from './app-pagination.vue'
import AppPassword from './app-password.vue'
import AppSearch from './app-search.vue'
import AppSwitch from './app-switch.vue'
import AppTitle from './app-title.vue'
import AppToast from './app-toast.vue'

export default {
  install (app: App) {
    app.component('AppButton', AppButton)
    app.component('AppEmpty', AppEmpty)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppImage', AppImage)
    app.component('AppInput', AppInput)
    app.component('AppInputFile', AppInputFile)
    app.component('AppInputSearch', AppInputSearch)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppModal', AppModal)
    app.component('AppPagination', AppPagination)
    app.component('AppPassword', AppPassword)
    app.component('AppSearch', AppSearch)
    app.component('AppSwitch', AppSwitch)
    app.component('AppTitle', AppTitle)
    app.component('AppToast', AppToast)
    app.component('FaIcon', AppIcon)
  },
}

declare module 'vue' {
  export interface GlobalComponents {
    AppButton: typeof AppButton,
    AppEmpty: typeof AppEmpty,
    AppErrorMsg: typeof AppErrorMsg,
    AppImage: typeof AppImage,
    AppInput: typeof AppInput,
    AppInputFile: typeof AppInputFile,
    AppInputSearch: typeof AppInputSearch,
    AppLoadingDot: typeof AppLoadingDot,
    AppModal: typeof AppModal,
    AppPagination: typeof AppPagination,
    AppPassword: typeof AppPassword,
    AppSearch: typeof AppSearch,
    AppSwitch: typeof AppSwitch,
    AppTitle: typeof AppTitle,
    AppToast: typeof AppToast,
    FaIcon: typeof AppIcon,
  }
}

declare global {
  type AppModal = InstanceType<typeof AppModal>
}
