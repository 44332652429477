import { intervalToDuration } from 'date-fns'

/**
 * Convert number into H:M:SS format.
 * 1500 -> 0:01.5
 * 50000 -> 0:50
 * 150000 -> 2:30
 * 600000 -> 10:00
 * 3600000 -> 1:00:00
 * 36000000 -> 10:00:00
 * 360000000 -> 100:00:00
 */
export default function (miliseconds: number) {
  // const { years, months, days, hours, minutes, seconds } = intervalToDuration({
  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: miliseconds,
  })

  const result = [
    String(minutes ?? 0).padStart(2, '0'),
    String(seconds ?? 0).padStart(2, '0'),
  ].join(':')

  const hour = (hours ?? 0)
  if (hour > 0) {
    return hour + ':' + result
  }
  return result
}
