import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { usePageStore } from '@/store/page'
import appointment from './appointment'
import auth from './auth'
import programOrder from './program-order'
import setting from './setting'

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    component: () => import('@/layouts/app/page-app.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/home/page-home.vue'),
        name: 'home',
      },
      {
        path: 'client',
        component: () => import('@/views/client/page-list.vue'),
        name: 'clients',
      },
      {
        path: 'client/:id/:name',
        component: () => import('@/views/client/show/page-show.vue'),
        name: 'client show',
        props: true,
      },
      {
        path: 'schedule',
        component: () => import('@/views/schedule/page-list.vue'),
        name: 'schedules',
      },
      {
        path: 'notification',
        component: () => import('@/views/notification/page-list.vue'),
        name: 'notification',
      },
      setting,
    ],
  },
  programOrder,
  appointment,
  auth,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  to; from
  const page = usePageStore()

  page.resetErrorState()
  next()
})

export default router
