import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import '@/styles/style.css'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import globalComponents from '@/components'
import globalDirectives from '@/directives'
import router from '@/routes'
import sentry from '@/services/sentry'
import App from './App.vue'

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)
  .use(router)
  .use(sentry, router)
  .use(pinia)
  .use(globalComponents)
  .use(globalDirectives)

app.mount('#app')
