function insertDashAfter (str: string, spaceAt: number[]) {
  spaceAt.forEach(at => {
    str = str.slice(0, at) + '-' + str.slice(at)
  })

  return str
}

/**
 * Get location where to insert a dash.
 * From the end for easier insert.
 */
function calculateSpace (phone: string) {
  switch (phone.length) {
  case 6: return [3]
  case 7: return [3]
  case 8: return [5, 2]
  case 9: return [5, 2]
  case 10: return [6, 2]
  case 11: return [7, 4, 2]
  case 12: return [8, 5, 2]
  case 13: return [9, 5, 2]
  case 14: return [10, 6, 2]
  default: return []
  }
}

/**
 * Format phone number for easier reading.
 *
 *  6: 012345 => 012-345
 *  7: 0123456 => 012-3456
 *  8: 01234567 => 0123-4567
 *  9: 621234567 => 62-123-4567
 * 10: 6231345678 => 62-31-345-678
 * 11: 62317451133 => 62-31-745-1133
 * 12: 628123456789 => 62-812-345-6789
 * 13: 6281234567890 => 62-812-3456-7890
 * 14: 62812345678901 => 62-8123-4567-8901
 */
export default function (phone: string) {
  const spaceAt = calculateSpace(phone)
  return insertDashAfter(phone, spaceAt)
}
