import { type RouteRecordRaw } from 'vue-router'

const auth: RouteRecordRaw = {
  path: '/',
  component: () => import('@/layouts/auth/page-auth.vue'),
  children: [
    {
      path: 'login',
      component: () => import('@/views/auth/page-login.vue'),
      name: 'login',
    },
    {
      path: 'forgot-password',
      component: () => import('@/views/auth/page-forgot-password.vue'),
      name: 'forgot password',
    },
    {
      path: 'password/reset',
      component: () => import('@/views/auth/page-reset-password.vue'),
      name: 'reset password',
    },
  ],
}

export default auth
