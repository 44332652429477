<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = withDefaults(
    defineProps<{src?: string|null, type?: 'default'|'person'|'question'}>(),
    { src: '', type: 'default' },
  )

  const error = ref(false)

  watch(() => props.src, () => {
    error.value = false
  })
</script>

<template>
  <div class="overflow-hidden">
    <div v-if="!src || error" class="h-full w-full flex items-center justify-center bg-gray-200 text-gray-500">
      <fa-icon v-if="type === 'person'" class="icon" icon="i-far-user-tie" />
      <fa-icon v-else-if="type === 'question'" class="icon" icon="i-far-question" />
      <fa-icon v-else class="icon" icon="i-far-image" />
    </div>
    <img
      v-else
      class="h-full w-full"
      :src="src"
      @error="error = true">
  </div>
</template>

<style lang="postcss" scoped>
.icon {
  @apply w-6/10 h-full;
}
</style>
