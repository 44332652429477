<script setup lang="ts">
  // import { AnchorHTMLAttributes, ButtonHTMLAttributes, computed } from 'vue'
  import { type ButtonHTMLAttributes, computed } from 'vue'
  import { type RouteLocationRaw } from 'vue-router'

  interface Props {// extends ButtonHTMLAttributes, AnchorHTMLAttributes {
    to?: RouteLocationRaw,
    loading?: boolean,
    disabled?: boolean,
    type?: ButtonHTMLAttributes['type'],
  }
  const props = defineProps<Props>()

  // render as link when `to` is provided, and button is not disabled
  // otherwise, render as button
  const is = computed(() => props.to && !(props.disabled || props.loading) ? 'router-link' : 'button')
</script>

<template>
  <component
    :is="is"
    :to="to"
    :type="is === 'router-link' ? undefined : (type ?? 'button')"
    :disabled="disabled || loading">
    <slot></slot>
    <app-loading-dot v-if="loading" />
  </component>
</template>
